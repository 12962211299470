<template>
  <ClientOnly>
    <div v-if="!userState.loading" class="flex flex-col h-screen">
      <NavigationBar />
      <VNotification
        :key="JSON.stringify(notification)"
        class="absolute top-22 right-4 w-96"
        :show="!!notification"
        :delay="notification ? notification.delay : 0"
        :type="notification?.type"
        @dismissed="setNotification(null)"
      >
        <template #title>{{ notification?.title }}</template>
        <template #default>
          <template v-if="notification && notification.text">
            {{ notification.text }}
          </template>
        </template>
      </VNotification>
      <div class="flex-grow">
        <slot />
      </div>
      <FooterDefault v-if="!userState.user" />
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
const { state: userState } = useUser()
const { notification, setNotification } = useGlobalNotification()
</script>
